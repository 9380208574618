import { template as template_d93f59cc69194c809830730d8093e627 } from "@ember/template-compiler";
const FKText = template_d93f59cc69194c809830730d8093e627(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
